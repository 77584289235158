<template>
  <div>
    <div class="main-title">推广记录</div>
    <div class="content-list-page page-content">
      <div class="list-head-box">
        <div data-v-69874ad0="" class="list-head">
          <p data-v-69874ad0="" class="list-head-title">温馨提示</p>
          <ul data-v-69874ad0="">
            ********
          </ul>
        </div>
      </div>

      <div class="padding-t-sm padding-b-sm">
        <span class="margin-l-sm fl-l margin-t-sm">
          <el-input
            v-model="searchData.promoter"
            placeholder="推广员昵称"
          ></el-input>
        </span>
        <span class="margin-l-sm fl-l margin-t-sm">
          <el-input
            v-model="searchData.adviser"
            placeholder="咨询师昵称"
          ></el-input>
        </span>
        <span class="margin-l-sm fl-l margin-t-sm">
          <el-select v-model="searchData.status" size="mini" placeholder="状态">
            <el-option label="待确认" :value="1"> </el-option>
            <el-option label="已完成" :value="2"> </el-option>
            <el-option label="撤销" :value="3"> </el-option>
          </el-select>
        </span>
        <span class="margin-l-sm fl-l margin-t-sm">
          <el-date-picker
            size="small"
            v-model="searchData.datePick"
            type="daterange"
            align="right"
            value-format="yyyy-MM-dd HH:mm:ss"
            unlink-panels
            range-separator="至"
            start-placeholder="点评日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </span>

        <span class="margin-l-sm fl-l margin-t-sm">
          <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="search"
            >搜索</el-button
          >
        </span>

        <span class="margin-l-sm fl-l margin-t-sm">
          <el-button type="primary" size="small" @click="derive"
            >导出</el-button
          >
        </span>

        <span class="margin-l-sm fl-l margin-t-sm">
          <el-button type="primary" size="small" @click="addRecord"
            >添加</el-button
          >
        </span>
      </div>

      <div class="table-content">
        <el-table
          :header-cell-style="{ padding: 0 }"
          ref="singleTable"
          :data="tableData"
          highlight-current-row
          v-loading="loading"
          style="width: 100%; background: #fff"
        >
          <el-table-column label="ID" property="id" width="80">
          </el-table-column>

          <el-table-column property="promoter_name" label="推广员">
          </el-table-column>
          <el-table-column label="报单ID" property="sales_id" width="80">
          </el-table-column>
          <el-table-column property="adviser_name" label="咨询师">
          </el-table-column>
          <el-table-column property="course" label="课程名称">
          </el-table-column>
          <el-table-column property="pay_type" label="支付方式">
            <template slot-scope="scope">
              <span>{{scope.row.pay_type == 1?'微信支付':'支付宝支付'}}</span>
            </template>
          </el-table-column>
          <el-table-column property="amount" label="支付金额">
          </el-table-column>
          <el-table-column property="commission" label="返佣金额">
          </el-table-column>
          <el-table-column property="sn" label="订单号"> </el-table-column>
          <el-table-column property="pay_sn" label="支付单号">
          </el-table-column>

          <el-table-column label="状态" width="100">
            <template slot-scope="scope">
              <span class="state-down" v-if="scope.row.status == 1"
                >待确认</span
              >
              <span class="state-up" v-else-if="scope.row.status == 2"
                >已完成
              </span>
              <span class="state-up" v-else-if="scope.row.status == 3"
                >撤销</span
              >
            </template>
          </el-table-column>

          <el-table-column property="address" label="操作">
            <template slot-scope="scope">
              <span @click="invalid(scope.row.id)" v-if="scope.row.status == 1">
                <el-link type="primary">置为无效</el-link>
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="table-batch">
        <span class="fl-r">
          <el-pagination
            small
            background
            :current-page="page"
            :page-sizes="[10, 20]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="setPageSize"
            @current-change="setPage"
            :total="total"
          >
          </el-pagination>
        </span>
        <div style="clear: both"></div>
      </div>

      <el-dialog
        title="添加"
        :visible.sync="dialogVisible"
        width="30%"
        append-to-body
      >
        <el-form
          :model="form"
          status-icon
          ref="ruleForm"
          label-width="100px"
          v-loading="formLoading"
          class="demo-ruleForm"
        >
          <el-form-item label="推广员">
            <el-select
              v-model="form.promoterId"
              filterable
              placeholder="请选择"
            >
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.nickname"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="订单号">
            <el-input v-model="form.salesId" placeholder="订单号"></el-input>
          </el-form-item>

          <el-form-item label="状态">
            <el-radio v-model="form.status" :label="1">待入账</el-radio>
            <el-radio v-model="form.status" :label="2">已入账</el-radio>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="submitForm()">提交</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import tools from "../../../utils/tools";
export default {
  name: "List",
  data() {
    return {
      tableData: [],
      searchData: {},
      options: [],
      loading: false,
      page: 1,
      pageSize: 10,
      total: 0,
      dialogVisible: false,
      visible: false,
      form: {},
      formLoading: false,
    };
  },
  methods: {
    ...mapActions("promotionRecord", [
      "listAllPromoterRecord",
      "exportAllPromoterRecords",
      "addPromoterRecord",
    ]),
    ...mapActions("popularize", ["selectListPromoter"]),
    ...mapActions("promote", ["invalidRecord"]),
    async derive() {
      this.downloadLoading2 = true;
      var vm = this;
      let postData = {
        page: vm.page,
        pageSize: vm.pageSize,
        courseName: vm.courseName,
      };
      if (vm.creator !== "") {
        postData.creator = vm.creator;
      }
      if (vm.selectStatus !== "") {
        postData.status = vm.selectStatus;
      }
      if (vm.course_type !== "") {
        postData.courseType = vm.course_type;
      }
      if (vm.class_type !== "") {
        postData.classType = vm.class_type;
      }
      if (vm.datePick !== null) {
        postData.datePick = vm.datePick;
      }
      if (this.creator !== "") {
        postData.creator = this.creator;
      }
      if (this.selectStatus !== "") {
        postData.status = this.selectStatus;
      }
      if (this.selectClassType !== "") {
        postData.classType = this.selectClassType;
      }
      if (this.datePick !== null) {
        postData.datePick = this.datePick;
      }
      const { res_info, data } = await this.exportAllPromoterRecords(
        this.searchData
      );
      if (res_info !== "ok") return;
      this.$message.success('添加到队列中')
      // this.$confirm("导出数据已完成,是否下载?", "温馨提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "success",
      // })
      //   .then(() => {
      //     this.$message.success('添加到队列中')
      //     // 下载xlsx
      //     // var blob =
      //     //   "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
      //     //   data;
      //     // var fileName = "教师录单" + tools.getFormatDate() + ".xlsx";
      //     // tools.downloadFileByBase64(blob, fileName);
      //   })
      //   .catch(() => {
      //     this.$message.info("已取消下载!");
      //   });
      this.downloadLoading2 = false;
    },
    async addRecord() {
      this.dialogVisible = true;
      this.formLoading = true;
      const { data } = await this.selectListPromoter();
      this.options = data;
      this.formLoading = false;
    },
    async invalid(id) {
      const { res_info } = await this.invalidRecord({ recordId: id });
      if (res_info != "ok") return;
      this.$message.success("成功");
      this.getList();
    },
    async apply(id) {},
    async submitForm() {
      const { res_info } = await this.addPromoterRecord(this.form);
      this.dialogVisible = false;
      if (res_info != "ok") return;
      this.$message.success("添加成功");
      this.getList();
    },
    search() {
      this.tableData = [];
      this.page = 1;
      this.getList();
    },
    async getList() {
      this.loading = true;
      let form = {
        ...this.searchData,
        page: this.page,
        pageSize: this.pageSize,
      };
      const { data } = await this.listAllPromoterRecord(form);
      this.tableData = data.list;
      this.total = data.total;
      this.loading = false;
    },
    setPageSize(pageSize) {
      this.pageSize = pageSize;
      this.getList();
    },
    setPage(page) {
      this.page = page;
      this.getList();
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style>
.selectPic {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
  overflow: hidden;
}

.selectPic p {
  float: left;
  width: 20%;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}

.selectPic p:first-child {
  cursor: default;
}
</style>
